@media print {
  :global {
    body {
      -webkit-print-color-adjust: exact;
    }

    body > *:not(.pdf) {
      display: none;
    }
    body > .pdf {
      display: block;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  width: 100%;
  page-break-after: avoid;
  color: #000000de;
  & *,
  & *::before,
  & *::after {
    box-sizing: border-box;
    font-size: inherit;
  }

  &.endPage {
    page-break-after: always;
  }

  %heading {
    margin: 0;
    color: inherit;
  }

  h1 {
    @extend %heading;
    font-size: 20px;

    &.title span {
      color: #545454;
      // margin-top: 20px;
    }
  }

  .subTitle {
    margin-bottom: 5px;
  }

  h2 {
    @extend %heading;
  }

  h3 {
    @extend %heading;
  }

  h4 {
    @extend %heading;
  }

  h5 {
    @extend %heading;
  }

  h6 {
    @extend %heading;
  }

  p {
    margin-top: 0;
    margin-bottom: 0px;
    font-size: inherit;
  }

  span > p {
    margin-bottom: 0px;
  }

  .textMute {
    font-size: 12px;
    font-style: italic;
    text-transform: capitalize;
  }

  .list {
    font-size: inherit;
    & * {
      font-size: 11px;
    }

    & img {
      height: 18px;
      width: 18px;
    }
  }

  .text {
    &,
    & * {
      font-size: 8px;
    }
  }

  .textCenter {
    text-align: center;
  }

  .image {
    object-fit: contain;
    width: -webkit-fill-available;
    height: auto;
  }

  .row {
    display: flex;
  }
  .twoRows {
    column-count: 2;
  }

  .col2 {
    width: 100%;
    max-width: 15%;
  }

  .col3 {
    width: 100%;
    max-width: 25%;
  }

  .col6 {
    width: 100%;
    max-width: 50%;
  }

  .col9 {
    width: 100%;
    max-width: 75%;
  }

  .col10 {
    width: 100%;
    max-width: 85%;
  }

  .col12 {
    width: 100%;
    max-width: 100%;
  }

  .mt-2 {
    margin-top: 6px;
  }
  .mt-3 {
    margin-top: 10px;
  }
  .mb-3 {
    margin-bottom: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 18px;
      color: rgba(35, 35, 35, 0.87);
    }
    padding: 5px;
    margin-top: 10px;
  }

  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-top: 0.5px solid rgba(224, 224, 224, 1);
    border-left: 0.5px solid rgba(224, 224, 224, 1);
    border-right: 0.5px solid rgba(224, 224, 224, 1);
    & > thead {
      & > tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0;
        & > th {
          background-color: #f5f5f5;
          vertical-align: inherit;
          border-bottom: 0.5px solid rgba(224, 224, 224, 1);
          text-align: center;
          padding: 8px;
          color: rgba(0, 0, 0, 0.87);
          z-index: 2;
        }
      }
    }

    & > tbody {
      & > tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0;
        & > td {
          vertical-align: inherit;
          border-bottom: 0.5px solid rgba(224, 224, 224, 1);
          text-align: center;
          // padding: 8px;
          color: rgba(0, 0, 0, 0.87);
          z-index: 2;
        }
      }
    }

    .image {
      object-fit: contain;
      width: 30px;
      height: auto;
    }
  }

  .flex {
    display: flex;
    gap: 15px;
  }
}
